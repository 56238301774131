import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import thumbnailNews from "../../static/images/groupe7.webp"
import News3CardDemo from "../components/cardUI"

const Evenement = () => (
    <Layout>
        <SEO title="Pour chaqu'un de vos événements, VIVR vous ccompagnes"/>
            <div className={"container"}>
                <div className={"features"}>
                    <div className={"feature__item2"}>
                        <div className={"row"}>
                            {/* <div className={"col-6 first"}>
                                <div className={"thumbnail"}>
                                    <img alt={"News"} src={thumbnailNews}/>
                                </div>
                            </div> */}

                            <div className={"col-12 first"}>
                                <div className={"feature__content"}>
                                    <h2>Nos photographes vous accompagnent <br/> de la séance d'engagement au jour-J </h2>
                                </div>
                            </div>
                            <div className={"col-12 second"}>
                            <News3CardDemo/>
                                    
                                
                            </div>
                                {/* <div className={"col-3"}>
                                    <div className={"card"}>
                                        <img alt={"Card"} src={pcard}/>
                                    </div>
                                </div> */}


                                
                                

                                
                                
                                
                            
                        </div>
                    </div>

                    
                </div> 
            </div>

    </Layout>
)

export default Evenement
